@keyframes slide-in-bottom {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.screenShot20221019At164 {
  position: absolute;
  margin: 0 !important;
  top: 1060px;
  left: -187px;
  width: 845px;
  height: 766px;
  flex-shrink: 0;
  object-fit: cover;
  opacity: 0.4;
  z-index: 2;
}
.file3Icon {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
}
.actionButton {
  position: relative;
  font-size: var(--font-size-base);
  font-family: var(--font-inconsolata);
  color: var(--color-gray-200);
  text-align: left;
}
.downloadwhitepaperbutton {
  cursor: pointer;
  border: 1px solid var(--marble-5001);
  padding: var(--padding-4xs);
  background-color: var(--marble-2001);
  align-self: stretch;
  border-radius: var(--br-xs);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-7xs);
  z-index: 3;
}
.caseMain4Icon,
.screenShot20220429At153 {
  position: absolute;
  margin: 0 !important;
  top: 1278px;
  left: 1038px;
  width: 415px;
  height: 458px;
  flex-shrink: 0;
  object-fit: cover;
  opacity: 0.4;
  z-index: 5;
}
.caseMain4Icon {
  top: 717px;
  left: 831px;
  width: 761.5px;
  height: 743.96px;
  z-index: 6;
}
.faq,
.videosectionParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.faq {
  max-width: 811px;
  margin: 0 auto;
  z-index: 7;
}
.videosectionParent {
  align-self: stretch;
  background-color: var(--marble-1001);
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  height: 2139px;
  flex-shrink: 0;
  position: relative;
  gap: var(--gap-xl);
}
.buyFounderGame {
  position: relative;
  font-size: var(--font-size-3xl);
  display: inline-block;
  font-family: var(--font-space-grotesk);
  color: var(--color-black);
  text-align: center;
  width: 385px;
}
.walletAltIcon {
  position: relative;
  width: 17.5px;
  height: 17.3px;
  flex-shrink: 0;
}
.actionButton1 {
  position: relative;
  text-shadow: 0 0 0 rgba(255, 255, 255, 0.01);
}
.primary {
  border-radius: var(--br-xs);
  background-color: var(--color-gray-200);
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  width: 385px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-7xs);
}
.actionButton2 {
  flex: 1;
  position: relative;
}
.buyFounderGamePassParent {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
}
.drawer,
.headerParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.drawer {
  align-self: stretch;
  border-radius: var(--br-md) var(--br-md) 0 0;
  background-color: var(--marble-1001);
  padding: var(--padding-4xl) 146px;
  align-items: flex-start;
  opacity: 0;
}
.drawer.animate {
  animation: 0.25s ease 0s 1 normal forwards slide-in-bottom;
}
.headerParent {
  position: relative;
  width: 100%;
  align-items: center;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-whitesmoke-100);
  font-family: var(--font-inconsolata);
}
@media screen and (max-width: 1200px) {
  .downloadwhitepaperbutton {
    display: none;
  }
}
@media screen and (max-width: 960px) {
  .downloadwhitepaperbutton {
    display: flex;
  }
  .faq {
    align-self: stretch;
    width: auto;
  }
}
@media screen and (max-width: 420px) {
  .downloadwhitepaperbutton {
    display: flex;
  }
}
