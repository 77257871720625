.icon {
  position: relative;
  width: 22.01px;
  height: 22.01px;
  flex-shrink: 0;
  fill: white;
}
.sidebarButton {
  border-radius: var(--br-sm);
  width: 36px;
  height: 40px;
  flex-shrink: 0;
  padding: var(--padding-6xs);
  box-sizing: border-box;
  cursor: pointer;
}
.sidebarButton,
.sidebarButton1,
.social {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sidebarButton1 {
  border-radius: var(--br-sm);
  width: 36px;
  height: 39px;
  flex-shrink: 0;
  padding: var(--padding-6xs);
  box-sizing: border-box;
}
.social {
  gap: var(--gap-6xs);
}
@media screen and (max-width: 1200px) {
  .social {
    flex-direction: column;
  }
}
@media screen and (max-width: 960px) {
  .social {
    flex-direction: row;
  }
}
