.videosection {
  align-self: stretch;
  position: relative;
  height: 520.5px;
  flex-shrink: 0;
  z-index: 0;
}
.videoContainer {
  position: relative;
  height: 0;
  padding-top: 56.25%;
  width:100%;
}
.video {
  position: absolute;
  top: 0; left: 0;
  border: 0;
  width: 100%;
  height: 100%;
}