.whitelistMintCountdown {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-weight: 700;
}
.days,
.p {
  position: relative;
  display: inline-block;
  width: 60px;
}
.p {
  margin: 0;
  line-height: 42px;
  font-weight: 700;
}
.days {
  font-size: var(--font-size-2xs);
  text-shadow: 0 0 0 rgba(255, 255, 255, 0.01);
}
.parent {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent,
.whitelistcountdown {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}
.frameParent {
  height: 54px;
  flex-shrink: 0;
  flex-direction: row;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-2xs);
  font-size: var(--font-size-7xl);
  font-family: var(--font-inconsolata);
}
.whitelistcountdown {
  flex-direction: column;
  gap: var(--gap-8xs);
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-space-grotesk);
}
@media screen and (max-width: 1200px) {
  .frameParent,
  .whitelistMintCountdown,
  .whitelistcountdown {
    align-self: stretch;
    width: auto;
  }
  .whitelistcountdown {
    height: auto;
  }
}
@media screen and (max-width: 960px) {
  .whitelistcountdown {
    align-self: stretch;
    width: auto;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 420px) {
  .frameParent {
    align-self: stretch;
    width: auto;
    padding-left: var(--padding-xl);
    box-sizing: border-box;
    max-width: 500px;
  }
  .frameParent,
  .whitelistcountdown {
    align-items: center;
    justify-content: center;
  }
}
