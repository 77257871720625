.panel{
    height: 679px;
    margin: 57px 14px 0px 14px;
}

.title {
    margin: '0 auto';
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    
}

.checkboxWrapper {
    display: flex;
    justify-content: center;
}

.paragraph{
    height: 479px;
    margin-top: 26px;
    margin-left: 20px;
    margin-right: 20px;
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    text-align: justify;
    color: #000000;
    max-height: 608px;
    overflow: auto;
}

.contentButtons{
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
}

.buttonCustom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 8px;
    width: 100%;
    max-width: 385px;
    height: 49px;
    background: #282828;
    border-radius: 12px;
    cursor: pointer;
}

.icon{
    width:'auto'
}

.text{
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    color: #F8F8F8;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.disabledBtn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 8px;
    width: 100%;
    max-width: 385px;
    height: 49px;
    left: 23px;
    top: 687px;
    background: gray;
    border-radius: 12px;
    word-wrap: break-word;
}




@media screen and (min-width: 900px){
    .paragraph{
        height: 500px;
    }
}

@media screen and (min-width: 1300px){
    .paragraph{
        height: 550px;
    }
}

