.areYouA {
  margin: 0;
  position: relative;
  font-size: 44px;
  font-weight: 700;
  font-family: inherit;
}
.cubeIcon,
.ifYourreAlready {
  position: relative;
  flex-shrink: 0;
}
.ifYourreAlready {
  align-self: stretch;
  display: inline-block;
  height: 104px;
  max-width: 500px;
  font-size: var(--font-size-4xl);
}
.cubeIcon {
  width: 16px;
  height: 17px;
}
.actionButton {
  position: relative;
  text-shadow: 0 0 0 rgba(255, 255, 255, 0.01);
  cursor: pointer;
}
.secondary {
  border-radius: var(--br-xs);
  background-color: var(--marble-2001);
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  border: 1px solid var(--marble-5001);
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs);
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-7xs);
  font-size: var(--font-size-base);
  color: var(--color-gray-200);
}
.desktoptext {
  border-radius: var(--br-sm);
  padding: var(--padding-lg) 0 var(--padding-2xs);
  gap: var(--gap-lg);
  font-size: var(--font-size-2xl);
  font-family: var(--font-inconsolata);
}
.desktoptext,
.sectioncreatortext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.cubeIcon1 {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
}
.actionButton1 {
  position: relative;
  font-size: var(--font-size-base);
  font-weight: 700;
  font-family: var(--font-inconsolata);
  color: var(--color-gray-200);
  text-align: left;
}
.launchpadbutton1 {
  cursor: pointer;
  border: 1px solid var(--marble-5001);
  padding: var(--padding-4xs);
  background-color: var(--marble-2001);
  border-radius: var(--br-xs);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.launchpadbutton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  z-index: 0;
}
.areyoucreator,
.sectioncreator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-3xl);
}
.areyoucreator {
  align-self: stretch;
  background-color: var(--onix-6001);
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  flex-direction: row;
  padding: 0 var(--padding-3xl);
  justify-content: space-around;
  gap: var(--gap-8xl);
  text-align: left;
  font-size: var(--font-size-9xl);
  color: var(--marble-1001);
  font-family: var(--font-space-grotesk);
  min-height: 110vh;
}
@media screen and (max-width: 1200px) {
  .areYouA,
  .ifYourreAlready {
    align-self: stretch;
    width: auto;
    flex: 1;
  }
  .ifYourreAlready {
    max-width: 500px;
  }
  .desktoptext {
    width: auto;
    height: auto;
    padding-top: 10%;
    box-sizing: border-box;
  }
  .launchpadbutton,
  .sectioncreatortext {
    width: auto;
    height: auto;
    justify-content: center;
  }
  .sectioncreatortext {
    flex-direction: column;
    align-items: flex-start;
  }
  .launchpadbutton {
    align-items: flex-end;
    display: none;
  }
  .areyoucreator,
  .sectioncreator {
    width: auto;
    height: auto;
    flex-direction: column;
    gap: var(--gap-0);
    align-items: center;
    justify-content: center;
  }
  .areyoucreator {
    flex-direction: row;
    gap: var(--gap-5xl);
    justify-content: flex-start;
    padding-left: 7%;
    padding-right: 7%;
    box-sizing: border-box;
    min-height: 110vh;
  }
}
@media screen and (max-width: 960px) {
  .areYouA {
    text-align: center;
  }
  .ifYourreAlready {
    font-size: var(--font-size-3xl);
  }
  .desktoptext {
    align-self: stretch;
    width: auto;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    display: none;
  }
  .sectioncreatortext {
    padding-bottom: 15%;
    box-sizing: border-box;
  }
  .launchpadbutton {
    width: auto;
    height: auto;
    flex-direction: column;
    padding-top: 15%;
    box-sizing: border-box;
    display: block;
  }
  .areyoucreator,
  .launchpadbutton,
  .sectioncreator {
    align-items: center;
    justify-content: center;
  }
  .areyoucreator {
    width: auto;
    height: auto;
    flex-direction: column;
    gap: var(--gap-0);
  }
}
@media screen and (max-width: 630px) {
  .areYouA {
    text-align: center;
  }
  .ifYourreAlready {
    font-size: var(--font-size-3xl);
  }
  .desktoptext {
    align-self: stretch;
    width: auto;
    flex: 1;
    display: none;
  }
  .sectioncreatortext {
    padding-bottom: 25%;
    box-sizing: border-box;
  }
  .actionButton1 {
    font-size: var(--font-size-sm);
  }
  .launchpadbutton {
    width: auto;
    height: auto;
    flex-direction: column;
    gap: var(--gap-0);
    align-items: center;
    justify-content: flex-end;
    padding-top: 15%;
    box-sizing: border-box;
    display: block;
  }
  .areyoucreator,
  .sectioncreator {
    align-items: center;
    justify-content: center;
  }
  .areyoucreator {
    width: auto;
    height: auto;
    flex-direction: column;
    gap: var(--gap-0);
    padding: 20% 10%;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 350px) {
  .areYouA {
    font-size: var(--font-size-6xl);
    align-self: stretch;
    width: auto;
  }
  .sectioncreatortext {
    align-items: center;
    justify-content: center;
    padding-bottom: 25%;
    box-sizing: border-box;
  }
  .actionButton1 {
    font-size: var(--font-size-xs);
    flex: 1;
    align-self: stretch;
    height: auto;
  }
  .launchpadbutton {
    width: auto;
    height: auto;
    flex-direction: column;
    padding-top: 15%;
    box-sizing: border-box;
  }
  .areyoucreator,
  .launchpadbutton,
  .sectioncreator {
    align-items: center;
    justify-content: center;
  }
  .areyoucreator {
    padding-left: 12%;
    padding-top: 40%;
    padding-right: 12%;
    box-sizing: border-box;
  }
}
