.walletAltIcon {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
}
.actionButton {
  position: relative;
  font-size: var(--font-size-base);
  font-family: var(--font-inconsolata);
  text-align: left;
}
.connectwalletbuttom {
  cursor: pointer;
  padding: var(--padding-4xs);
  background-color: var(--color-gray-200);
  border-radius: var(--br-xs);
  display: flex;
  flex-direction: row;
  gap: var(--gap-7xs);
  align-items: center;
  justify-content: center;
  height: 49px;
  box-sizing: border-box;
  border: 1px;
  color: var(--color-whitesmoke-100);
}
