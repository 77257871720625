.panel{
    width: 100%;
    height: auto;
}

.logoWrapper {
    width: 28px;
}

.iconLyrz{
    margin-top: 27px;
    margin-bottom: 31.25px;
}

.contentCard{
    flex: 1;
    height: 366px;
    position: relative;
    background: #F7F7F7;
    border-radius: 45px;
    margin: 0 auto;
}

.nftInfo {
    flex: 1;
}

.contentNft{
    position: absolute;
    width: 100%;
    height: 100%;
    top: calc(-100% + 90%);
}
.nft{
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title{
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #000000;
    margin-top: 41px;
}

.contentButtons{
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
}

.buttonCustomDark {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 8px;
    width: 100%;
    max-width: 385px;
    height: 49px;
    left: 23px;
    top: 687px;
    background: #282828;
    border-radius: 12px;
    cursor: pointer;
}

.buttonCustomWhite {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 8px;
    width: 100%;
    max-width: 385px;
    height: 49px;
    left: 23px;
    top: 687px;
    background: #FFFDFD;
    border-radius: 12px;
    border: 1px solid #E1E1E1;
    cursor: pointer;
}

.icon{
    width:'auto'
}

.textDark{
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    color: #282828;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.textWhite{
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    color: #F8F8F8;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.contentAddress{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 10px;
    height: 28px;
    margin-top: 16px;
}

.textAddress{
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 0;
    display: flex;
    align-items: center;
}

.contentFee{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 16px;
    height: 44px;
    margin-top: 24px;
}

.textFee{
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 44px;
    text-align: center;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.textMatic{
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: #000000;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.polygonExplorer {
    color: #000000;
    text-decoration: none;
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    cursor: pointer;
}

.contentFlex{
    display: flex;
    width: 550px;
    gap: 10px;
    justify-content: space-around;
    flex-direction: row;
}

@media screen and (max-width: 900px){
    .panel{
        width: 100vw;
        height: 100vh;
    }

    .contentCard,
    .nftInfo {
        flex: auto;
    }

    .contentCard {
        max-width: 385px;
    }

    .contentFlex{
        width: auto;
        flex-direction: column;
        gap: 0px;
    }
    .contentNft {
        position: relative;
        top: auto;
    }
}

@media screen and (max-width: 394px){
    /* .contentCard{
        width: 100vw;
        height: calc(100vw - 18px);
    } */
    /* .contentNft{
        width: 100%;
        height: 100%;
        //left: 0px;
        //stop: calc(-100% + 80%);
    } */
    /* .nft{
        width: 100vw;
    } */
    .contentFlex{
        flex-direction: column;
        gap: 0px;
    }
}

