@keyframes slide-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.brandtitle {
  flex: 1;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.sectiondescription,
.sectiontitle {
  margin: 0;
  align-self: stretch;
  position: relative;
}
.sectiontitle {
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
}
.sectiondescription {
  font-size: var(--font-size-3xl);
  font-family: var(--font-inconsolata);
}
.joinwhitelistbuttonParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.children,
.childrenInner,
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.childrenInner {
  align-self: stretch;
  justify-content: center;
}
.children,
.section {
  gap: var(--gap-xs);
}
.children {
  align-self: stretch;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-base);
}
.section {
  flex: 1;
  justify-content: flex-start;
}
.nftpreviewParent {
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: var(--gap-6xl);
}
.frameWrapper,
.nftpreviewParent,
.socialParent {
  display: flex;
  flex-direction: row;
}
.background {
  background-image: url(../../public/frame-496@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
}
.headerWrapper {
  padding: var(--padding-3xs) var(--padding-md);
}
.socialParent {
  flex: 1;
  padding: 0 var(--padding-md);
  align-items: center;
  justify-content: center;
  gap: var(--gap-2xl);
  height: 100vh;
}
.frameWrapper {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0;
  text-align: left;
  font-size: var(--font-size-8xl);
  color: var(--color-black);
  font-family: var(--font-space-grotesk);
}
.frameWrapper.animate {
  animation: 0.25s ease 0s 1 normal forwards slide-in-left;
}
@media screen and (max-width: 1200px) {
  .brandtitle {
    display: none;
  }
  .sectiontitle {
    font-size: var(--font-size-8xl);
    text-align: left;
  }
  .sectiondescription {
    font-size: var(--font-size-lg);
  }
  .joinwhitelistbuttonParent {
    height: auto;
    flex-direction: row;
  }
  .children,
  .childrenInner {
    align-self: stretch;
    width: auto;
  }
  .children {
    align-items: center;
    justify-content: center;
  }
  .nftpreviewParent {
    flex: 1;
  }
  .nftpreviewParent,
  .socialParent {
    flex-direction: row;
    gap: var(--gap-xs);
  }
}
@media screen and (max-width: 960px) {
  .headerWrapper {
    display: none;
  }
  .brandtitle {
    display: flex;
    flex: unset;
    align-self: stretch;
  }
  .sectiontitle {
    font-size: var(--font-size-5xl);
    text-align: center;
  }
  .sectiondescription {
    display: none;
  }
  .joinwhitelistbuttonParent {
    align-self: center;
    height: auto;
    flex-direction: column;
    max-width: 500px;
  }
  .childrenInner {
    align-self: stretch;
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .nftpreviewParent,
  .section {
    flex: unset;
    align-self: stretch;
  }
  .nftpreviewParent,
  .socialParent,
  .frameWrapper {
    flex-direction: column;
  }
  .frameWrapper {
    align-items: center;
  }
  .socialParent {
    flex: 1;
    box-sizing: border-box;
    height: auto;
    padding: var(--padding-lg) var(--padding-lg);
  }
  .socialRight {
    display: none;
  }
  .joinwhitelistbuttonParent,
  .customButton {
    width: 100%;
  }
}
@media screen and (max-width: 630px) {
  .joinwhitelistbuttonParent {
    height: auto;
    align-items: center;
    justify-content: center;
  }
  .childrenInner {
    align-self: stretch;
    width: auto;
  }
  .nftpreviewParent {
    gap: var(--gap-xs);
  }
  .background {
    width: 100%;
  }
  .socialParent {
    padding: var(--padding-xs) var(--padding-5xs);
    box-sizing: border-box;
    gap: var(--gap-4xs);
  }
  .sectiontitle {
    font-size: var(--font-size-3xl);
  }
}
