.itemcreatorChild {
  border-radius: var(--br-sm);
  max-width: 6rem;
  overflow: hidden;
  max-height: 100%;
}
.learnHowToGuarantee,
.metaverseArtists {
  margin: 0;
  align-self: stretch;
  position: relative;
}
.metaverseArtists {
  font-size: var(--font-size-4xl);
  font-weight: 700;
  font-family: inherit;
}
.learnHowToGuarantee {
  font-size: var(--font-size-4xl);
  font-family: var(--font-inconsolata);
  display: flex;
  align-items: center;
  height: 75px;
  flex-shrink: 0;
}
.itemcreator,
.metaverseArtistsParent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.metaverseArtistsParent {
  align-self: stretch;
  width: 312px;
  flex-shrink: 0;
  flex-direction: column;
  gap: var(--gap-7xs);
}
.itemcreator {
  border-radius: var(--br-sm);
  flex-direction: row;
  padding: 0 0 var(--padding-5xs);
  gap: var(--gap-xs);
  z-index: 2;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--marble-1001);
  font-family: var(--font-space-grotesk);
}
@media screen and (max-width: 1200px) {
  .itemcreatorChild {
    width: auto;
    height: auto;
    max-width: 6rem;
  }
  .learnHowToGuarantee,
  .metaverseArtists {
    align-self: stretch;
    width: auto;
    flex: 1;
  }
  .metaverseArtistsParent {
    flex: 1;
    align-self: stretch;
    height: auto;
    gap: var(--gap-7xs);
  }
  .itemcreator {
    width: auto;
    height: auto;
    gap: var(--gap-xs);
    align-items: center;
    justify-content: flex-end;
    padding-top: 0;
    padding-bottom: var(--padding-xs);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 960px) {
  .learnHowToGuarantee,
  .metaverseArtists {
    text-align: center;
  }
  .itemcreator {
    align-self: stretch;
    width: auto;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 630px) {
  .metaverseArtists {
    text-align: center;
  }
  .learnHowToGuarantee {
    /* font-size: var(--font-size-3xl); */
    text-align: center;
  }
  .metaverseArtistsParent {
    width: auto;
  }
  .itemcreator {
    align-self: stretch;
    width: auto;
    flex: 1;
    flex-direction: column;
  }
}
@media screen and (max-width: 350px) {
  /* .learnHowToGuarantee,
  .metaverseArtists {
    font-size: var(--font-size-xl);
  } */
  .learnHowToGuarantee {
    text-align: center;
    align-self: stretch;
    width: auto;
    flex: 1;
  }
  .itemcreator {
    width: auto;
    height: auto;
    align-items: center;
    justify-content: center;
  }
}
