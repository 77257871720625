.panel{
    height: 400px;
    margin: 69px 14px 0px 14px;
}

.title {
    margin: '0 auto';
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #000000;
}

.contentButtons{
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
}

.buttonCustom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 8px;
    width: 100%;
    max-width: 385px;
    height: 49px;
    left: 23px;
    top: 687px;
    background: #282828;
    border-radius: 12px;
}

.icon{
    width:'auto'
}

.text{
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    color: #F8F8F8;
    flex: none;
    order: 1;
    flex-grow: 0;
}

@media screen and (max-width: 768px) {
    .panel {
        height: 45vh;
    }
}
