.title {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 44px;
  font-weight: 700;
  font-family: inherit;
  margin-bottom: 5%;
}
.frameParent,
.sectioncreator {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: var(--gap-md);
  font-size: var(--font-size-3xl);
  color: var(--color-gray-200);
}
.frameParent {
  flex-direction: row;
  justify-content: space-around;
}
.sectioncreator {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.01);
  flex-direction: column;
  justify-content: center;
  gap: var(--gap-8xl);
  text-align: center;
  font-size: var(--font-size-9xl);
  color: var(--color-black);
  font-family: var(--font-space-grotesk);
  min-height: 100vh;
}
@media screen and (max-width: 1200px) {
  .title {
    align-self: stretch;
    width: auto;
  }
  .frameParent {
    flex-direction: row;
  }
}
@media screen and (max-width: 960px) {
  .frameParent {
    flex-direction: column;
    gap: var(--gap-4xl);
  }
  .sectioncreator {
    flex-direction: column;
    gap: var(--gap-4xl);
    padding: var(--padding-2xl) var(--padding-md);
  }
}
@media screen and (max-width: 420px) {
  .frameParent {
    flex-direction: column;
    gap: var(--gap-lg);
  }
}
