.icon {
  position: relative;
  width: auto;
  height: 32px;
  flex-shrink: 0;
  object-fit: cover;
}

.partners {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--padding-3xs);
  gap: var(--gap-3xs);
  box-sizing: border-box;
}
@media screen and (max-width: 960px) {
  .partners {
    align-self: stretch;
    width: auto;
    gap: var(--gap-4xs);
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--padding-5xs);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 630px) {
  .partners {
    gap: var(--gap-7xs);
  }
  .icon {
    height: 19px;
  }
  .partners {
    padding: 0 5px;
  }
}