.screenshot20220402at215Icon {
  position: relative;
  border-radius: var(--br-lg);
  width: 199px;
  height: 199px;
  flex-shrink: 0;
  object-fit: cover;
}
.screenshot20220402at215Wrapper {
  align-self: stretch;
  border-radius: var(--br-xl);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.name {
  align-self: stretch;
  position: relative;
}
.frameParent,
.nameParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent {
  align-items: center;
  gap: var(--gap-3xs);
  text-align: center;
  font-size: var(--font-size-3xl);
  color: var(--color-gray-200);
  font-family: var(--font-space-grotesk);
  text-decoration: none;
}
