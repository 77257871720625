.faqsInner,
.section {
  color: var(--color-black);
}
.section {
  position: relative;
  font-size: 44px;
  font-family: var(--font-space-grotesk);
  text-align: center;
  margin-bottom: 12%;
  max-width: 350px;
}
.faqsInner {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.whatAreThe {
  flex: 1;
  position: relative;
}
.plusIcon {
  position: relative;
  width: 20px;
  height: 24px;
  flex-shrink: 0;
}
.whatAreTheFundsBeingUsedParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.frameChild {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--marble-4001);
  box-sizing: border-box;
  height: 1px;
  flex-shrink: 0;
}
.frameParent {
  align-self: stretch;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.faqs,
.faqsChild,
.frameParent {
  display: flex;
  flex-direction: column;
}
.faqsChild {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}
.faqs {
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
  max-width: 811px;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--onix-2001);
  font-family: var(--font-inconsolata);
  padding: 295px 0px 150px;
  padding-bottom: 200px;
}

.paragraph{
  width: 100%;
  justify-content: flex-start;
  justify-self: start;
  justify-items: start;
  text-align: left;

}
.contentCollapsed{
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

@media screen and (max-width: 1100px) {
  .section {
    font-size: var(--font-size-4xl);
    line-height: 31px;
  }
}

@media screen and (max-width: 960px) {
  .faqs {
    width: calc(100% - 48px);
    margin: 0 auto;
  }
}
@media screen and (max-width: 630px) {
  .faqs {
    width: calc(100% - 48px);
    margin: 0 auto;
    font-size: var(--font-size-base);
    padding: 120px 0px 150px;
  }
  .section {
    max-width: 100%;
  }
}
