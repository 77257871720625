@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-inconsolata: Inconsolata;
  --font-space-grotesk: "Space Grotesk";
  --label-1-center: Inconsolata;
  --font-space-grotesk: "Space Grotesk";
  /* font sizes */
  --font-size-2xs: 11px;
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-base: 16px;
  --font-size-lg: 19px;
  --font-size-xl: 20px;
  --font-size-2xl: 21px;
  --font-size-3xl: 24px;
  --font-size-4xl: 27px;
  --font-size-5xl: 30px;
  --font-size-6xl: 32px;
  --font-size-7xl: 35px;
  --font-size-8xl: 40px;
  --font-size-9xl: 44px;

  /* Colors */
  --marble-1001: #fff;
  --marble-2001: #fffdfd;
  --color-gray-200: #282828;
  --onix-6001: #141414;
  --color-whitesmoke-100: #f8f8f8;
  --marble-4001: #ebebeb;
  --color-black: #000;
  --marble-5001: #e1e1e1;
  --onix-2001: #6c6c6c;
  --onix-4001: #3a3a3a;
  --marble-7001: #c6c6c6;
    /* Colors */
    --marble-100: #fff;
    --onix-800: #000;
    --onix-400: #3a3a3a;
    --marble-200: #fffdfd;
    --color-gray-200: #282828;
    --onix-600: #141414;
    --color-whitesmoke-100: #f8f8f8;
    --marble-400: #ebebeb;
    --marble-500: #e1e1e1;
    --onix-200: #6c6c6c;
    --marble-700: #c6c6c6;

  /* Gaps */
  --gap-0: 0px;
  --gap-8xs: 4px;
  --gap-7xs: 8px;
  --gap-6xs: 10px;
  --gap-5xs: 11px;
  --gap-4xs: 15px;
  --gap-3xs: 16px;
  --gap-2xs: 24px;
  --gap-xs: 30px;
  --gap-sm: 32px;
  --gap-md: 33px;
  --gap-lg: 40px;
  --gap-xl: 42px;
  --gap-2xl: 49px;
  --gap-3xl: 54px;
  --gap-4xl: 56px;
  --gap-5xl: 60px;
  --gap-6xl: 71px;
  --gap-7xl: 100px;
  --gap-8xl: 160px;

  /* Paddings */
  --padding-6xs: 8px;
  --padding-5xs: 15px;
  --padding-4xs: 16px;
  --padding-3xs: 21px;
  --padding-2xs: 24px;
  --padding-xs: 30px;
  --padding-sm: 35px;
  --padding-md: 41px;
  --padding-lg: 50px;
  --padding-xl: 64px;
  --padding-2xl: 66px;
  --padding-3xl: 100px;
  --padding-4xl: 103px;
  --padding-5xl: 107px;
  --padding-6xl: 160px;

  /* border radiuses */
  --br-xs: 12px;
  --br-sm: 16px;
  --br-md: 46px;
  --br-lg: 131px;
  --br-xl: 259px;
  /* border radiuses */
  --br-2xs: 8px;

    /* font sizes */
    --font-size-3xs: 10px;
    --font-size-2xs: 11px;
    --font-size-xs: 12px;
    --font-size-sm: 14px;
    --label-1-center-size: 16px;
    --font-size-lg: 18px;
    --font-size-xl: 19px;
    --font-size-2xl: 20px;
    --font-size-3xl: 21px;
    --font-size-4xl: 24px;
    --font-size-5xl: 27px;
    --font-size-6xl: 30px;
    --font-size-7xl: 32px;
    --font-size-8xl: 35px;
    --font-size-9xl: 40px;
    --font-size-10xl: 42px;
    --font-size-11xl: 44px;
      /* Colors */
  --marble-500: #e1e1e1;
  --marble-200: #fffdfd;
  --onix-500: #282828;
  --onix-6001: #141414;
  --onix-800: #000;
  --marble-300: #f8f8f8;
  --marble-4003: #ebebeb;
  --marble-700: #c6c6c6;
  --marble-100: #fff;
  --onix-4004: #3a3a3a;
  --onix-2003: #6c6c6c;

    /* Gaps */
    --gap-0: 0px;
    --gap-10xs: 4px;
    --gap-9xs: 8px;
    --gap-8xs: 10px;
    --gap-7xs: 11px;
    --gap-6xs: 12px;
    --gap-5xs: 15px;
    --gap-4xs: 16px;
    --gap-3xs: 19px;
    --gap-2xs: 20px;
    --gap-xs: 24px;
    --gap-sm: 30px;
    --gap-md: 32px;
    --gap-lg: 33px;
    --gap-xl: 40px;
    --gap-2xl: 42px;
    --gap-3xl: 49px;
    --gap-4xl: 54px;
    --gap-5xl: 56px;
    --gap-6xl: 58px;
    --gap-7xl: 60px;
    --gap-8xl: 71px;
    --gap-9xl: 100px;
    --gap-10xl: 160px;
  
    /* Paddings */
    --padding-9xs: 8px;
    --padding-8xs: 15px;
    --padding-7xs: 16px;
    --padding-6xs: 17px;
    --padding-5xs: 20px;
    --padding-4xs: 21px;
    --padding-3xs: 24px;
    --padding-2xs: 30px;
    --padding-xs: 35px;
    --padding-sm: 41px;
    --padding-md: 50px;
    --padding-lg: 64px;
    --padding-xl: 66px;
    --padding-2xl: 80px;
    --padding-3xl: 100px;
    --padding-4xl: 103px;
    --padding-5xl: 107px;
    --padding-6xl: 120px;
    --padding-7xl: 146px;
    --padding-8xl: 160px;
  
    /* border radiuses */
    --br-2xs: 8px;
    --br-xs: 12px;
    --br-sm: 16px;
    --br-md: 46px;
    --br-lg: 131px;
    --br-xl: 259px;
}
