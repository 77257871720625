.socialWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.lyrzLabsO {
  font-size: var(--font-size-3xl);
}
.lyrzLabsOContainer {
  align-self: stretch;
  position: relative;
  line-height: 114.9%;
  display: inline-block;
  height: 31px;
  flex-shrink: 0;
}
.frameParent {
  align-self: stretch;
  background-color: var(--onix-6001);
  display: flex;
  flex-direction: column;
  padding: var(--padding-2xl) 0;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-4xs);
  text-align: center;
  font-size: var(--font-size-4xl);
  color: var(--marble-1001);
  font-family: var(--font-inconsolata);
}

@media screen and (max-width: 420px) {
  .frameParent {
    padding: var(--padding-sm) 0;
  }
}