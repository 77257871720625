.img {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}
.nftpreview {
  flex: 1;
  height: 579.67px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .img {
    align-self: stretch;
    width: auto;
  }
  .nftpreview {
    flex: 1;
  }
}
@media screen and (max-width: 960px) {
  .nftpreview {
    height: auto;
    max-height: 500px;
    flex: unset;
    align-self: stretch;
  }
}
@media screen and (max-width: 420px) {
  .img {
    width: 100%;
    margin: 0 auto;
  }
}
